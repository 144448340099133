import React, { useState, useEffect } from 'react';
import { Modal, Image, Button } from 'antd';

import Picture1 from '../img/HGC_2023/1.jpg';
import Picture2 from '../img/HGC_2023/2.jpg';
import Picture3 from '../img/HGC_2023/3.jpg';
import Picture4 from '../img/HGC_2023/4.jpg';
import Picture5 from '../img/HGC_2023/5.jpg';
import Picture6 from '../img/HGC_2023/6.jpg';
import Picture7 from '../img/HGC_2023/7.jpg';
import Picture8 from '../img/HGC_2023/8.jpg';
import Picture9 from '../img/HGC_2023/9.jpg';
import Picture10 from '../img/HGC_2023/10.jpg';
import Picture11 from '../img/HGC_2023/11.jpg';
import Picture12 from '../img/HGC_2023/12.jpg';
import Picture13 from '../img/HGC_2023/13.jpg';
import Picture14 from '../img/HGC_2023/14.jpg';
import Picture15 from '../img/HGC_2023/15.jpg';
import Picture16 from '../img/HGC_2023/16.jpg';

const Gallery = ({ year, title, btnTitle }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [dynamicImport, setDynamicImport] = useState([]);

  useEffect(() => {
    const onLoad = async () => {
        try {
            const imagePaths = await import(`../img/HGC_${year}/HeroesGolf${year}Images`);
            setDynamicImport(imagePaths.default);
        } catch (error) {
            console.log('error loading image paths', error);
        }

        console.log('import', dynamicImport);
    }

    onLoad();
  }, [year]);

  const displayGallery = () => {
    setShowGallery(true);
  }

  const handleOk = () => {
    setShowGallery(false);
  }

  const handleCancel = () => {
    setShowGallery(false);
  }

  return (
    <>
      <Button type='primary' onClick={displayGallery}>
        {btnTitle}
      </Button>
      <Modal
        title={title}
        visible={showGallery}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ width: '900px' }}
      >
        <h1>Gallery</h1>
        <Image.PreviewGroup
          preview={{
            onchange: (current, prev) => console.log(`current index; ${current}, prev index: ${prev}`),
          }}
        >
            {dynamicImport.map((image, index) => (
                <Image width={150} src={image} />
            ))}
        </Image.PreviewGroup>
      </Modal>
    </>
  )
}

export default Gallery;