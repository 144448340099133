import React, { Component } from 'react';
import { PatternFormat } from 'react-number-format';
import ReactLoading from 'react-loading';

import axios from 'axios';
import { toast } from '@rickylandino/react-messages';


export class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFields: {
                contactEmail: "",
                firstName: "",
                lastName: "",
                contactPhone: "",
                contactContent: ""
            },
            loading: false,
            disabled: true,
            messageSuccess: false,
            messageFail: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    handleSubmit = async () => {
        let postdata = this.state.formFields;
        if (!postdata.firstName ||
            !postdata.lastName ||
            !postdata.contactEmail ||
            !postdata.contactContent) {
            toast.error("Please fill in all required fields");
        } else {
            toast.loading("Sending message...", { key: 'loading' });
            axios.post('contact', postdata).then(response => {
                if (response.data) {
                    toast.destroy('loading');
                    toast.success("Your message has been sent. We will get back to you as soon as possible.");

                    this.setState({
                        formFields: {
                            contactEmail: "",
                            firstName: "",
                            lastName: "",
                            contactPhone: "",
                            contactContent: ""
                        }
                    })
                } else {
                    toast.error("Something went wrong");
                }
                
            });
        }
    };


    render() {
        return (
            <div className="marginTop25">

                <div className="row">
                    <div className="col col-lg-12 col-12">
                        <h1>
                            Contact Us
                            </h1>
                        <div className="form-group">
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <label className="form-label">First Name <span className="required">*</span></label>
                                    <input className="form-control" htmlFor="fName" name="firstName" placeholder="Enter your first name" aria-label="Enter your first name" value={this.state.formFields.firstName} onChange={this.handleInputChange} />
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <label className="form-label">Last Name <span className="required">*</span></label>
                                    <input className="form-control" htmlFor="lName" name="lastName" placeholder="Enter your last name" aria-label="Enter your last name" value={this.state.formFields.lastName} onChange={this.handleInputChange} />
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <label className="form-label">Email <span className="required">*</span></label>
                                    <input className="form-control" type="email" name="contactEmail" placeholder="Enter your email" aria-label="Enter your email" value={this.state.formFields.contactEmail} onChange={this.handleInputChange} />
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <label className="form-label">Phone Number</label>
                                    <PatternFormat className="form-control" name="contactPhone" value={this.state.formFields.contactPhone} onChange={this.handleInputChange} format="(###) ###-####" placeholder="Enter your phone number" mask="_" />
                                </div>
                                <div className="col col-lg-12 col-12">
                                    <label className="form-label">Message <span className="required">*</span></label>
                                    <textarea rows="10" name="contactContent" className="form-control" placeholder="Type your message here..." value={this.state.formFields.contactContent} onChange={this.handleInputChange}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="row">
                    <div className="col-sm-12">
                        <button onClick={this.handleSubmit} className="btn btn-submit mt-3"><i className="fa fa-magic"></i> Send Message</button>
                        {this.state.loading &&
                            <span><ReactLoading className='tableLoading' type={"spokes"} color={'#7acfd6'} height={30} width={30} /></span>
                        }

                    </div>
                    {this.state.messageSuccess &&
                        <p>Thank you for reaching out! We will be in touch shortly.</p>
                    }
                    {this.state.messageFail &&
                        <p>Something went wrong, please send us an email directly.</p>
                    }
                </div>

            </div>
        );
    }
}