import React, { Component } from 'react';

import Carousel from 'react-bootstrap/Carousel'
import golfer from '../img/golfer.png';
import swim from '../img/swim_event.jpg';
import golfHeader from '../img/golf_tournament_header.jpg';
//import 'font-awesome/css/font-awesome.min.css';

class Events extends Component {

    render() {
        return (
            <div>
                <Carousel
                    interval='50000'
                    pause='hover'
                    nextIcon={<i className="fa fa-chevron-right chevron-icon"></i>}
                    prevIcon={<i className="fa fa-chevron-left chevron-icon"></i>}
                >
                    <Carousel.Item>
                        <div>
                            <h2>2nd Annual Heroes Golf Classic</h2>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div><strong><em>Two Organizations - One Mission - Never Forget</em></strong></div>
                                    <div>Thursday, August 15, 2024 <span className="ms-3"></span></div>
                                    <div>Tunxis Country Club - 87 Town Farm Road, Farmington CT</div>
                                    <div>$150 per golfer, which includes lunch, dinner, and green fees. $75 per person if you would rather just attend dinner.</div>
                                    <div><a href="https://www.golfinvite.net/heroes" target="_blank" and rel="noopener noreferrer">Register Here!</a></div>
                                </div>
                                <div className="col-lg-6">
                                    <img src={golfHeader} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div>
                            <h2>4th Annual Swim-A-Thon</h2>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div><strong>Raise pledges for swimming 100 laps in support of SCOOPSFORTROOPS.ORG and the Travis Mills Foundation for Recalibrated Veterans</strong></div>
                                    <div>Saturday, July 13th, 2024 - 8:00am</div>
                                    <div>Shuttle Meadow Country Club - 51 Randecker Lane, Berlin, CT</div>
                                    <div><a href="https://www.classy.org/campaign/scoops-for-troops-swim-a-thon-2024/c515619" target="_blank" and rel="noopener noreferrer">Donate Here!</a></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center col-lg-6">
                                    <img src={swim} className="img-fluid" style={{maxHeight: '325px'}} />
                                </div>
                            </div>
                        </div>

                    </Carousel.Item>
                    
                </Carousel>
                
            </div>
        );
    }
}

export default Events;