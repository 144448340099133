import React, { Component, Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { toast } from '@rickylandino/react-messages';

import LazyHero from 'react-lazy-hero';
import hero from '../img/hero.JPG';
import { Contact } from './Contact';
import img from '../img/logo.png';
import img2 from '../img/logo2.png';

import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import cookie from 'react-cookies';
import Events from './Stories';
import Gallery from './Gallery';
import CarouselGallery from './CarouselGallery';


export function Home() {
        return (
            <div>
                <header>
                    <nav className="navbar navbar-expand-sm">
                        <div className="white-bg-slant"></div>
                        <a className="navbar-brand center-logo-mobile" id="homeBtn"><img id="homeBtn" src={img} /></a>
                    </nav>

                </header>
                <LazyHero
                    className="hero"
                    imageSrc={hero}
                    rel='preload'
                    color="#000"
                    opacity={0.4}
                    minHeight={'600px'}
                    parallaxOffset={10}>

                    <h1 className="hero">Scoops For Troops</h1>
                    <h4 className="hero">For the love of veterans and ice cream</h4>
                </LazyHero>
                <div className="containerCustom">
                    <h2>
                        About the Campaign
                    </h2>
                    <p>At eight years old Michael's vision started innocently enough. At the 2017 4th of July parade in Belgrade, Michael, his then 11-year-old sister Kara and his parents, noticed a wounded soldier across the street.
                        The soldier was from the Travis Mills Foundation retreat, which was under development in Mount Vernon, Maine. Michael and Kara asked if they could go across the street to say "thank you." That started Michael's journey of awareness of military service and of the
                        obstacles faced by soldiers who had been injured in service and their families. Michael said, "I don't want birthday presents this year. I want to raise money so that the children who go to the retreat can get ice cream on the boat like we like to do in the summer.
                        This is my way of saying thank you to the kids for having hero parents." The $400 goal was reached so quickly that Michael began to think bigger - and beyond just ice cream. Michael quickly adopted the $30,000 yearly goal to send eight families to the retreat.
                        Since that day over $75,000 has been raised and Scoopsfortroops.org is a registered 501c3 organization.
                    </p>
                    <p>
                Check out the fundraising page <a href="https://www.classy.org/campaign/scoops-for-troops-2023-2024/c515311" target="_blank" rel="noopener noreferrer">HERE</a>
                    </p>

                    <p>
                        <a href="https://www.facebook.com/scoopsfortroops.org" target="_blank" rel="noopener noreferrer"><FaFacebookSquare /> Connect with us on Facebook</a>
                    </p>

                    <p>
                        <a href="https://www.instagram.com/scoopsfortroopsct/" target="_blank" rel="noopener noreferrer"><FaInstagram /> Connect with us on Instagram</a>
                    </p>
                    <h1>
                        Congressional Medal of Honor Society, Youth Services Award
                    </h1>
                    <p>
                        Medal of Honor Recipients selected Michael Ahern of Plainville, Connecticut as the 2022 Citizen Honors Award for Youth Service Honoree for his commitment to supporting wounded veterans.
                        <br /><br />
                        At the age of 9, Michael founded Scoops for Troops, a fundraising endeavor centered around ice cream, that focused on sending wounded veterans and their families to a week-long retreat hosted by the Travis Mills Foundation. In less than one year, they raised more than $30,000, enabling eight families to attend this life enhancing camp. His goal is to raise $500,000 before attending college. Michael’s passion and commitment reflect great credit upon himself, his family, and the state of Connecticut.

                    </p>
                    <div className="text-center">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ItCeIMs_iZY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>

              <Events />
                
              <br /><br />
              <div className="row">
                <h1>Pictures From The 2024 Heroes Golf Classic!</h1>
                <CarouselGallery year={'2024'}/>
                <Gallery btnTitle={"View Full Gallery"} title={"2024 Heroes Golf Classic"} year={2024} />
                <br />
              </div>
              <br />
              <br />
              <div className="row">
                <h1>Pictures From Last Year's 2023 Heroes Golf Classic!</h1>
                <CarouselGallery year={'2023'}/>
                <Gallery btnTitle={"View Full Gallery"} title={"2023 Heroes Golf Classic"} year={2023} />
              </div>
              <br /><br />


                    <div className="row">
                        <div className="col col-lg-8 col-12">
                             <Contact />
                        </div>
                        <div className="col-lg-4 col-12 pt-3">
                            <table id="wrapper">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                <img src={img2} width={250} className="img-fluid" alt="Scoops for Troops Logo" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }