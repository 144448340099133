import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Image } from 'antd';

const CarouselGallery = ({ year }) => {

    /*
     *
     * Based on year passed via props, will dynamically call import function 
     * that is inside the specific heroes golf image folder.
     * 
     * this export will contain a list of all images files to be rendered
     * on the carousel and can be mapped through
     * 
     * this can be refactored again to take full folder paths if needing to render
     * different image collections with this carousel
     * 
     */

    const [dynamicImport, setDynamicImport] = useState([]);

    useEffect(() => {
    const onLoad = async () => {
        try {
            const imagePaths = await import(`../img/HGC_${year}/HeroesGolf${year}Images`);
            setDynamicImport(imagePaths.default);
        } catch (error) {
            console.log('error loading image paths', error);
        }

        console.log('import', dynamicImport);
    }

    onLoad();
    }, [year]);

	return (
	    <Carousel>
            {dynamicImport.map((image, index) => (
                <Carousel.Item key={index}>
                    <Image width={720} src={image} />
                </Carousel.Item>
            ))}
        </Carousel>
	)
}

export default CarouselGallery;