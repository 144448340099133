import React, { Component } from 'react';
import img from '../img/logo.png';


export class Layout extends Component {
    render() {
        return (
            <div className="page-wrapper background">
                <div className="page-inner">
                    <div className="page-content-wrapper">
                        
                        <main id="js-page-content" role="main" className="page-content">
                            {this.props.children}
                        </main>

                        <footer className="page-footer">
                            <div className="page-footer-text">
                                &#169; {(new Date().getFullYear())} Scoops For Troops
                            </div>
                            <div className="float-end">
                                <img src={img} width={250}/>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}
